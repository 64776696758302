import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  languageCode: string
  pax?: string
  services?: string[]
  size?: string
  title: string
  URL: string
}

export const Room = memo(function Room({
  image,
  languageCode,
  pax,
  services,
  size,
  title,
  URL,
}: Props) {
  return (
    <Container to={URL}>
      <BackgroundImage>{image ? <Image {...image} /> : null}</BackgroundImage>
      <Wrapper row wrap>
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          <CTA
            className="room-cta"
            label={useVocabularyData('take-a-seat', languageCode)}
            iconArrowRight
            noActions
            variant="S"
          />
        </LeftSide>
        <RightSide>
          {size ? <Service>{size}</Service> : null}
          {pax ? <Service>{pax}</Service> : null}
          {services
            ? services.map((item, index) => (
                <Service key={index}>{item}</Service>
              ))
            : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  display: block;
  margin-top: 1.5rem;
  &:hover {
    img {
      transform: scale(1.1);
    }
    .room-cta {
      letter-spacing: 0.075rem;
      svg {
        transform: translateX(-1.125rem);
      }
    }
  }
`

const BackgroundImage = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  overflow: hidden;
  padding-bottom: 70%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }
`

const Wrapper = styled(FlexBox)`
  width: calc(100% - 4.625rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2.625rem 3rem 3rem;
  transform: translate(2.3125rem, -3.75rem);

  @media (max-width: 1023px) {
    width: calc(100% - 1.5rem);
    padding: 1.875rem 3.375rem;
    transform: translate(0.75rem, -1.875rem);
  }
`

const LeftSide = styled.div`
  width: 60%;
  padding-right: 1.5rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
`

const RightSide = styled.div`
  width: 40%;

  @media (max-width: 1023px) {
    display: none;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
`

const CTA = styled(Button)`
  margin-top: 1.875rem;

  @media (max-width: 1023px) {
    margin-top: 1.4375rem;
  }
`

const Service = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  padding-left: 1.0625rem;
  position: relative;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 0;
  }
`
